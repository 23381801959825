/**
 * Created by anatoly on 14.11.2024
 */

var ActiveCalendarTileComponent = cc.Node.extend({
    ctor: function (tile) {
        this._super();

        this.tile = tile;

        this.createTitleBg();
        this.createTitle();
        this.animateShowTitle();
    },

    isLast: function () {
        return this.tile.isLast;
    },

    animateShowTitle: function () {
        this.titleBg.setOpacity(0);
        this.titleBg.runAction(
            new cc.FadeIn(0.3).easing(cc.easeIn(2))
        );
    },

    createTitleBg: function () {
        var styles = cleverapps.styles.ActiveCalendarTileComponent;

        if (cleverapps.config.ui === "wooden") {
            this.titleBg = new cc.Node();
        } else if (bundles.prizecalendar.frames.title_bg) {
            this.titleBg = cleverapps.UI.createScale9Sprite(bundles.prizecalendar.frames.title_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        } else if (this.tile.state === PrizeCalendarTile.STATE_WAITING) {
            this.titleBg = cleverapps.UI.createScale9Sprite(bundles.prizecalendar.frames.title_bg_locked, cleverapps.UI.Scale9Rect.TwoPixelXY);
        } else {
            this.titleBg = cleverapps.UI.createScale9Sprite(bundles.prizecalendar.frames.title_bg_unlocked, cleverapps.UI.Scale9Rect.TwoPixelXY);
        }

        this.titleBg.setCascadeOpacityEnabled(true);

        this.titleBg.setContentSize2(styles.titleBg);
        this.titleBg.setPositionRound(styles.titleBg);

        this.addChild(this.titleBg);
    },

    createTitle: function () {
        var styles = cleverapps.styles.ActiveCalendarTileComponent;

        if (this.tile.state === PrizeCalendarTile.STATE_WAITING) {
            this.title = new cleverapps.CountDownView(
                this.tile.countDown,
                {
                    font: cleverapps.styles.FONTS.PRIZE_CALENDAR_TITLE,
                    showHours: false
                }
            );
            this.title.fitTo(styles.title.width, styles.title.height);

            if (cleverapps.rewardedAdsManager.isRewardedAvailable()) {
                this.addAdIcon();
            } else {
                cleverapps.rewardedAdsManager.onRewardedRefresh(this.addAdIcon.bind(this), this);
            }
        } else if (!this.tile.price) {
            this.title = cleverapps.UI.generateTTFText("PrizeCalendarTile.Claim", cleverapps.styles.FONTS.PRIZE_CALENDAR_TITLE);
            this.title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            this.title.setDimensions(styles.title.width, 0);
            this.title.fitTo(undefined, styles.title.height);
        } else {
            this.title = new TextWithIcon(this.tile.price + "$$", {
                font: cleverapps.styles.FONTS.PRIZE_CALENDAR_TITLE,
                width: styles.title.width,
                height: styles.title.height
            });
        }

        this.title.setCascadeOpacityEnabled(true);
        this.title.setPositionRound(styles.title);

        this.titleBg.addChild(this.title);
    },

    addAdIcon: function () {
        if (!["heroes", "wooden"].includes(cleverapps.config.ui)) {
            return;
        }

        var icon = new cc.Sprite(bundles.prizecalendar.frames.ad_icon_png);
        icon.setPositionRound(cleverapps.styles.ActiveCalendarTileComponent.adIcon);

        this.titleBg.addChild(icon);
        if (cleverapps.config.ui === "wooden") {
            icon.setRotation(25);
        }

        icon.setVisible(false);
        icon.runAction(StandartAnimations.showUp(icon, { flash: true }));
    },

    onReceiveTile: function (rewardList, callback) {
        if (["heroes", "wooden"].indexOf(cleverapps.config.ui) === -1) {
            this.afterReceiveTile(rewardList);
            callback();
        } else {
            this.bgReceiveAnimation(rewardList, callback);
        }
    },

    bgReceiveAnimation: function (rewardList, callback) {
        this.titleBg.runAction(new cc.FadeOut(0.3).easing(cc.easeOut(2)));

        var tileView = this.getParent();
        var bg = cleverapps.config.ui === "heroes" ? tileView.outerBg : tileView.innerBg;

        this.beforeReceiveAnimation(rewardList);

        bg.setAnimation(0, this.isLast() ? "receive_animation_last" : "receive_animation", false);
        bg.setCompleteListener(function () {
            this.afterReceiveTile(rewardList, callback);
        }.bind(this));
    },

    beforeReceiveAnimation: function (rewardList) {
        if (!["heroes", "wooden"].includes(cleverapps.config.ui)) {
            return;
        }

        var styles = cleverapps.styles.PrizeCalendarTileView.rewards;
        var tileView = this.getParent();

        var rewardIcon = this.rewardIcon = rewardList.rewards[0].getIcon();
        cleverapps.UI.fitToBox(rewardIcon, styles.fitToBox);

        tileView.addChild(rewardIcon, -1);
        rewardIcon.setPositionRound(styles);
    },

    afterReceiveTile: function (rewardList, callback) {
        if (this.isLast()) {
            var window = cleverapps.windows.currentWindow();

            window.onChestAnimation(rewardList);
            return;
        }

        this.collect(rewardList, callback);
    },

    collect: function (rewardList, callback) {
        var reward = this.getParent().rewards;

        if (reward) {
            rewardList.collectRewardsAnimation(reward, {
                flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,
                allAtOnce: true,
                small: false,
                beginScale: reward.content ? reward.content.scale : this.scale,
                callback: callback
            });

            return;
        }

        if (!this.rewardIcon) {
            return;
        }

        reward = this.rewardIcon;

        var tileView = this.getParent();

        var scene = cleverapps.scenes.getRunningScene();
        var bg = tileView.innerBg || tileView.outerBg;

        var baseZOrder = bg.getLocalZOrder();
        var baseParent = bg.getParent();

        if (cleverapps.config.ui === "wooden") {
            bg.replaceParentSamePlace(scene);
            bg.setLocalZOrder(scene.movingNode.getLocalZOrder() + 1);
        }

        reward.replaceParentSamePlace(cleverapps.scenes.getMovingNode());
        reward.runAction(new cc.Sequence(
            cc.JumpAnimation(0.5, reward, cc.p(0, 200), 200),
            new cc.CallFunc(function () {
                if (cleverapps.config.ui === "wooden") {
                    bg.replaceParentSamePlace(baseParent);
                    bg.setLocalZOrder(baseZOrder);
                }

                rewardList.collectRewardsAnimation(reward, {
                    callback: callback
                });
            })
        ));
    }
});

cleverapps.styles.ActiveCalendarTileComponent = {
    titleBg: {
        width: 145,
        height: 50,
        x: { align: "center" },
        y: { align: "top", dy: 15 }
    },

    title: {
        x: { align: "center" },
        y: { align: "center" },
        width: 140,
        height: 45
    }
};