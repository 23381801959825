/**
 * Created by Ivan on 20.03.2025
 */

ExclamationView.prototype.showMessage = function (message, type, callback) {
    var styles = cleverapps.styles.Exclamations;
    var sound = message === "message.YouWin" ? bundles.main.urls.sound_win_effect : bundles.exclamations.urls.keep_going_effect;

    var font = cleverapps.styles.FONTS.CONGRATS_MESSAGE_FONT;
    var fontColor = styles[message];
    if (fontColor) {
        font.color = fontColor.color;
    } else if (type === Exclamation.Types.Warning) {
        font.color = styles["warning"].color;
    } else {
        font.color = styles["default"].color;
    }

    var animation = "lets_go";
    if (cleverapps.exclamation.getCongratulations().indexOf(message) !== -1) {
        sound = bundles.game.urls[message + "_effect"];
        animation = message;
        message = "message." + message;
    } else if (message === "message.KeepGoing") {
        sound = bundles.exclamations.urls.go_on_effect;
    } else if (message === "message.Shuffle") {
        animation = "shuffle";
        sound = bundles.exclamations.urls.shuffle_effect;
    }

    this.createAnimation(animation, callback);
    this.createMsg(message, font);
    this.playSound(sound);
};

ExclamationView.prototype.createText = function (message, font) {
    var msg = cleverapps.UI.LabelTTF.GetLocalizedText(message);
    var text = cleverapps.UI.generateTTFText(message, font);
    if (msg.indexOf(" ") !== -1) {
        text.setDimensions(this.width, 0);
    } else {
        text.fitTo(this.width);
    }
    text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
    text.setPositionRound(this.width / 2, this.height / 2);
    this.addChild(text);

    return text;
};

ExclamationView.prototype.createMsg = function (message, font) {
    var text = this.createText(message, font);
    text.setRotation(10);

    var textScale = 1;
    text.setScale(0.1);
    text.setVisible(false);

    text.runAction(new cc.Sequence(
        new cc.DelayTime(0.2),
        new cc.Show(),
        new cc.ScaleTo(0.3, textScale).easing(cc.easeInOut(3)),
        new cc.DelayTime(0.55),
        new cc.ScaleTo(0.3, 0.1 * textScale),
        new cc.RemoveSelf()
    ));
};

ExclamationView.prototype.playSound = function (sound) {
    var styles = cleverapps.styles.Exclamations;

    var delay = styles.sound ? styles.sound.delay : 0;
    this.runAction(new cc.Sequence(
        new cc.DelayTime(delay),
        new cc.CallFunc(function () {
            cleverapps.audio.playSound(sound);
        })
    ));
};

cleverapps.styles.Exclamations = cleverapps.overrideStyles(cleverapps.styles.Exclamations, {
    width: 760,
    scale: [0.8, 1, 1],
    sound: { delay: 0.6 },

    "default": {
        color: cleverapps.styles.COLORS.CONGRATS_BLUE
    },
    "warning": {
        color: cleverapps.styles.COLORS.CONGRATS_DARK_RED
    },
    "yeah": {
        color: cleverapps.styles.COLORS.CONGRATS_RED
    },
    "super": {
        color: cleverapps.styles.COLORS.CONGRATS_LIGHT_GREEN
    },
    "yay": {
        color: cleverapps.styles.COLORS.CONGRATS_BLUE
    },
    "lets_go": {
        color: cleverapps.styles.COLORS.CONGRATS_ORANGE
    },
    "ohyeah": {
        color: cleverapps.styles.COLORS.CONGRATS_PURPLE
    },
    "oh": {
        color: cleverapps.styles.COLORS.CONGRATS_GREEN
    },
    "wow": {
        color: cleverapps.styles.COLORS.CONGRATS_DARK_RED
    }
}, true);