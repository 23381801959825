/**
 * Created by decipaliz on 22.05.2024
 */

var PrizeCalendar = function (mission) {
    this.mission = mission;

    this.createTiles();
};

PrizeCalendar.prototype.createTiles = function () {
    this.tiles = [];

    for (var i = 0; i < this.getTilesAmount(); i++) {
        var tile = new PrizeCalendarTile({
            config: RewardsConfig.PrizeCalendar.stages[i],
            isLast: i === this.getTilesAmount() - 1,
            onClick: this.onTileClick.bind(this),
            onDoubleClick: this.resetTimer.bind(this)
        });

        this.tiles.push(tile);

        tile.changeState(this.getTileState(i));
        if (tile.state === PrizeCalendarTile.STATE_WAITING) {
            tile.setTimer(this.getTimeLeft());
        }
    }
};

PrizeCalendar.prototype.onTileClick = function (tile) {
    switch (tile.state) {
        case PrizeCalendarTile.STATE_WAITING:
            if (this.getTimeLeft() > 0) {
                this.onShowAds();
            }
            break;
        case PrizeCalendarTile.STATE_ACTIVE:
            this.onSpendHard(tile.price);
            break;
    }
};

PrizeCalendar.prototype.getClaimableTileIndex = function () {
    return this.mission.result;
};

PrizeCalendar.prototype.getTileIndex = function (tile) {
    return this.tiles.indexOf(tile);
};

PrizeCalendar.prototype.getClaimableTile = function () {
    return this.tiles[this.getClaimableTileIndex()];
};

PrizeCalendar.prototype.getTileState = function (tileIndex) {
    if (tileIndex >= this.getTilesAmount()) {
        return;
    }
    if (tileIndex < this.getClaimableTileIndex()) {
        return PrizeCalendarTile.STATE_RECEIVED;
    }
    if (tileIndex > this.getClaimableTileIndex()) {
        return PrizeCalendarTile.STATE_LOCKED;
    }

    var stage = RewardsConfig.PrizeCalendar.stages[tileIndex];

    if (stage.free || stage.hard) {
        return PrizeCalendarTile.STATE_ACTIVE;
    }
    if (stage.timer) {
        return PrizeCalendarTile.STATE_WAITING;
    }
};

PrizeCalendar.prototype.getTimeLeft = function () {
    var duration = PrizeCalendar.TIMER_DURATION;
    return (this.mission.details.timerStarted || 0) + duration - Date.now();
};

PrizeCalendar.prototype.onClaimTile = function () {
    var tile = this.getClaimableTile();

    this.mission.update(1);

    Mission.logEvent(cleverapps.EVENTS.MISSION_ROUND, this.mission.getName(), {
        index: this.mission.result
    });

    var actions = [
        function (f) {
            tile.changeState(this.getTileState(this.getTileIndex(tile)));
            tile.onClaimTile(f);

            Mission.logEvent(cleverapps.EVENTS.MISSION_REWARD_COST, this.mission.getName(), {
                value: RewardsList.calcCost(tile.rewardsList)
            });
            Mission.logEvent(cleverapps.EVENTS.MISSION_REWARD + "_" + this.getTileIndex(tile), this.mission.getName());

            this.mission.logic.checkCompletion();
        }.bind(this),
        function (f) {
            if (this.getClaimableTileIndex() < this.getTilesAmount()) {
                var nextTile = this.getClaimableTile();

                nextTile.changeState(this.getTileState(this.getTileIndex(nextTile)));

                if (nextTile.state === PrizeCalendarTile.STATE_WAITING) {
                    this.mission.update(0, { timerStarted: Date.now() });
                    nextTile.setTimer(this.getTimeLeft());
                }

                nextTile.open();
            }
            f();
        }.bind(this)
    ];

    new ActionPlayer(actions).play();
};

PrizeCalendar.prototype.resetTimer = function () {
    this.mission.update(0, {});
};

PrizeCalendar.prototype.isAdsReady = function () {
    return cleverapps.adsLimits.state(AdsLimits.TYPES.PRIZE_CALENDAR) === AdsLimits.STATE_READY;
};

PrizeCalendar.prototype.onShowAds = function () {
    if (this.isAdsReady()) {
        cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.PRIZE_CALENDAR, this.onClaimTile.bind(this));
    }
};

PrizeCalendar.prototype.onSpendHard = function (price) {
    if (cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.PRIZE_CALENDAR, price)) {
        this.onClaimTile();

        Mission.logEvent(cleverapps.EVENTS.SPENT.HARD, this.mission.getName(), {
            value: price
        });
    }
};

PrizeCalendar.prototype.getTilesAmount = function () {
    return this.mission.logic.getTilesAmount();
};

PrizeCalendar.TILES_IN_ROW = 3;
PrizeCalendar.TIMER_DURATION = cleverapps.parseInterval("20 minutes");