/**
 * Created by vladislav on 22.01.2021
 */

var BonusWorldLevelView = cc.Node.extend({
    ctor: function (level) {
        this._super();

        this.level = level;
        this.styles = cleverapps.styles.BonusWorldLevelView[this.level.bonusWorld.type];

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(this.styles);

        this.createBg();
        this.createText();
        if (this.level.type === BonusWorldLevel.TYPE_CURRENT) {
            this.createIndicator();
        }

        this.onChangeType();

        cleverapps.aims.registerTarget("targetLevel" + this.level.levelNo, this);

        this.level.on("changeType", this.createListener(this.animateChangeType.bind(this)));
    },

    adjustPosition: function (bgScale) {
        var dx = this.styles.levels[this.level.levelNo].x * bgScale;
        var dy = this.styles.levels[this.level.levelNo].y * bgScale;

        var bgSize = cleverapps.resolution.getBgSize();
        dy = Math.max(dy, -(bgSize.height / 2 - this.height));
        dy = Math.min(dy, bgSize.height / 2 - this.height);
        dx = Math.max(dx, -(bgSize.width / 2 - this.width));
        dx = Math.min(dx, bgSize.width / 2 - this.width);

        this.setPosition(this.parent.convertToNodeSpace(cc.p(bgSize.width / 2 + dx, bgSize.height / 2 + dy)));
    },

    createTooltip: function () {
        cleverapps.tooltipManager.create(this, {
            text: this.level.type === BonusWorldLevel.TYPE_NOT_PASSED ? "BonusWorldNotPassedLevelTooltip" : "BonusWorldPassedLevelTooltip",
            location: this.level.levelNo >= 3 ? cleverapps.UI.Tooltip.LOCATION_BELOW : cleverapps.UI.Tooltip.LOCATION_ABOVE
        });
    },

    createFinger: function () {
        this.finger = FingerView.hintTap(this, {
            delay: 5,
            repeatDelay: 5
        });
    },

    createBg: function () {
        var json = this.level.bonusWorld.bundle.jsons.level_json;
        var scaleX = 1.0;
        if (this.styles.levels[this.level.levelNo].scaleX) {
            json = this.level.bonusWorld.bundle.jsons.level_2_json;
            scaleX = this.styles.levels[this.level.levelNo].scaleX;
        }
        this.bg = new cleverapps.Spine(json);
        this.addChild(this.bg);
        this.bg.setPositionRound(this.width / 2, this.height / 2);
        if (this.styles.skins) {
            this.bg.setSkin("skin_" + this.level.levelNo % this.styles.skins);
        }
        this.bg.setScaleX(scaleX);

        var animation = "current";
        if (this.level.type === BonusWorldLevel.TYPE_PASSED) {
            animation = "passed";
        }
        if (this.level.type === BonusWorldLevel.TYPE_NOT_PASSED) {
            animation = "not_passed";
        }
        this.bg.setAnimation(0, animation, true);
    },

    createIndicator: function () {
        if (!this.styles.indicator) {
            return;
        }

        var indicator = this.indicator = new cleverapps.Spine(this.level.bonusWorld.bundle.jsons.level_indicator);
        this.addChild(indicator, this.styles.indicator.zOrder || 0);
        indicator.setPositionRound(this.width / 2, this.height / 2);
        indicator.setAnimation(0, this.getIndicatorAnimation("idle"), true);
    },

    createText: function () {
        if (this.styles.skinNumbers) {
            if (this.level.type !== BonusWorldLevel.TYPE_PASSED) {
                this.bg.setSkin("" + this.level.levelNo);
            }
        } else {
            this.text = cleverapps.UI.generateImageText(this.level.levelNo + 1, cleverapps.styles.FONTS.BONUS_WORLD_LEVEL_TEXT);
            this.addChild(this.text);
            this.text.setPositionRound(this.styles.text);
        }
    },

    onChangeType: function () {
        if (this.clickHandler) {
            this.clickHandler.remove();
            this.clickHandler = undefined;
        }
        if (this.hoverHandler) {
            this.hoverHandler.remove();
            this.hoverHandler = undefined;
        }
        cleverapps.tooltipManager.remove(this);

        FingerView.remove(this.finger);
        this.finger = undefined;

        if (this.level.type === BonusWorldLevel.TYPE_PASSED) {
            this.createTooltip();
        }
        if (this.level.type === BonusWorldLevel.TYPE_CURRENT) {
            this.hoverHandler = cleverapps.UI.applyHover(this);
            this.clickHandler = cleverapps.UI.onClick(this, this.onClick.bind(this));
            this.createFinger();
        }
        if (this.level.type === BonusWorldLevel.TYPE_NOT_PASSED) {
            this.createTooltip();
        }
    },

    animateChangeType: function () {
        if (this.styles.indicator) {
            this.animateIndicatorChange();
        } else {
            if (this.level.type === BonusWorldLevel.TYPE_CURRENT) {
                this.bg.setAnimationAndIdleAfter("np-c", "current");
            }

            if (this.level.type === BonusWorldLevel.TYPE_PASSED) {
                this.bg.setAnimationAndIdleAfter("c-p", "passed");

                this.runAction(new cc.Sequence(
                    new cc.DelayTime(1.0),
                    new cc.PlaySound(this.level.bonusWorld.bundle.urls.pass_level_effect)
                ));
            }
        }

        this.onChangeType();
    },

    animateIndicatorChange: function () {
        if (this.level.type === BonusWorldLevel.TYPE_CURRENT) {
            var prevLevel = cleverapps.aims.getTarget("targetLevel" + (this.level.levelNo - 1));
            var endPos = cc.p(this.width / 2, this.height / 2);

            var indicator = this.indicator = prevLevel.indicator;
            delete prevLevel.indicator;
            indicator.replaceParentSamePlace(this);
            indicator.setAnimation(0, this.getIndicatorAnimation("animation"), false);
            cleverapps.audio.playSound(this.level.bonusWorld.bundle.urls.jump_sfx);

            this.bg.setAnimation(0, "np-c", false);

            if (this.styles.jumpHeight) {
                indicator.runAction(new cc.Sequence(
                    new cc.JumpTo(this.styles.delay, endPos, this.styles.jumpHeight, 1),
                    new cc.CallFunc(function () {
                        indicator.setAnimation(0, this.getIndicatorAnimation("idle"), true);
                        this.bg.setAnimation(0, "current", true);
                    }.bind(this))
                ));
            } else {
                indicator.runAction(new cc.Sequence(
                    new cc.DelayTime(this.styles.delay),
                    new cc.CallFunc(function () {
                        indicator.setPosition(endPos);
                        indicator.setAnimationAndIdleAfter("animation_invert", "idle");
                        this.bg.setAnimation(0, "current", true);
                    }.bind(this))
                ));
            }
        }

        if (this.level.type === BonusWorldLevel.TYPE_PASSED) {
            this.bg.setAnimationAndIdleAfter("c-p", "passed");

            if (this.level.levelNo === this.level.bonusWorld.levelsAmount - 1) {
                var target = cleverapps.aims.getTarget("finalPrizeTarget" + this.level.bonusWorld.type);
                this.indicator.replaceParentSamePlace(target);
                this.indicator.setAnimationAndIdleAfter("animation", "idle_static");

                if (this.styles.finalPrizePos) {
                    endPos = cc.p(target.width / 2 + this.styles.finalPrizePos.x, target.height / 2 + this.styles.finalPrizePos.y);
                    this.indicator.runAction(new cc.JumpTo(this.styles.delay, endPos, this.styles.jumpHeight, 1));
                    cleverapps.audio.playSound(this.level.bonusWorld.bundle.urls.jump_sfx);
                }
            }
        }
    },

    getIndicatorAnimation: function (name) {
        return name + (this.styles.levels[this.level.levelNo].invertIndicator ? "_invert" : "");
    },

    onClick: function () {
        this.level.onClick();
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BONUS_WORLD_LEVEL_TEXT: {
        name: "default",
        size: 40
    }
});

cleverapps.styles.BonusWorldLevelView = {};
cleverapps.styles.BonusWorldLevelView[BonusWorld.TYPE_VALENTINES] = {
    width: 130,
    height: 100,
    text: {
        x: { align: "center" },
        y: { align: "center" }
    },

    levels: [
        {
            x: -84,
            y: -550
        },
        {
            x: 53,
            y: -600,
            scaleX: 1
        },
        {
            x: 220,
            y: -550,
            scaleX: -1
        },
        {
            x: 100,
            y: -350
        },
        {
            x: -30,
            y: -307,
            scaleX: -1
        },
        {
            x: -190,
            y: -300,
            scaleX: -1
        },
        {
            x: -245,
            y: -210
        },
        {
            x: -205,
            y: -90,
            scaleX: -1
        },
        {
            x: 60,
            y: -47,
            scaleX: 1
        },
        {
            x: 210,
            y: -10
        }, {
            x: 200,
            y: 110,
            scaleX: -1
        },
        {
            x: 30,
            y: 135
        }, {
            x: -130,
            y: 159,
            scaleX: -1
        },
        {
            x: -190,
            y: 280,
            scaleX: 1
        },
        {
            x: -80,
            y: 370,
            scaleX: 1
        }
    ]
};
cleverapps.styles.BonusWorldLevelView[BonusWorld.TYPE_EASTER] = {
    width: 130,
    height: 160,
    skins: 3,
    text: {
        x: { align: "center" },
        y: { align: "center", dy: 10 }
    },

    levels: [
        {
            x: -149,
            y: -490
        },
        {
            x: 12,
            y: -515
        },
        {
            x: 159,
            y: -470
        },
        {
            x: 97,
            y: -314
        },
        {
            x: -48,
            y: -320
        },
        {
            x: -194,
            y: -305
        },
        {
            x: -256,
            y: -198
        },
        {
            x: -253,
            y: -68
        },
        {
            x: -113,
            y: 10
        },
        {
            x: 54,
            y: -19
        },
        {
            x: 190,
            y: 7
        },
        {
            x: 99,
            y: 102
        },
        {
            x: -29,
            y: 149
        },
        {
            x: -148,
            y: 241
        },
        {
            x: -125,
            y: 363
        }
    ]
};

cleverapps.styles.BonusWorldLevelView[BonusWorld.TYPE_SUMMER] = {
    width: 75,
    height: 105,
    skinNumbers: true,
    indicator: true,
    jumpHeight: 60,
    delay: 0.99,

    text: {
        x: { align: "center" },
        y: { align: "center" }
    },

    finalPrizePos: {
        x: -10,
        y: 50
    },

    levels: [
        {
            x: -224,
            y: -551
        },
        {
            x: -115,
            y: -445
        },
        {
            x: -41,
            y: -347
        },
        {
            x: 89,
            y: -298
        },
        {
            x: 211,
            y: -252
        },
        {
            x: 273,
            y: -175
        },
        {
            invertIndicator: true,
            x: 179,
            y: -119
        },
        {
            invertIndicator: true,
            x: 43,
            y: -81
        },
        {
            invertIndicator: true,
            x: -95,
            y: -67
        },
        {
            invertIndicator: true,
            x: -225,
            y: -31
        },
        {
            x: -132,
            y: 47
        },
        {
            x: -31,
            y: 104
        },
        {
            x: 74,
            y: 140
        },
        {
            invertIndicator: true,
            x: 17,
            y: 206
        },
        {
            invertIndicator: true,
            x: -74,
            y: 255
        }
    ]
};

cleverapps.styles.BonusWorldLevelView[BonusWorld.TYPE_XMAS] = {
    width: 85,
    height: 85,
    skinNumbers: true,
    indicator: {
        zOrder: -1
    },
    jumpHeight: 0,
    delay: 1.2,

    finalPrizePos: undefined,

    text: {
        x: { align: "center" },
        y: { align: "center" }
    },

    levels: [
        {
            x: 73,
            y: -464
        },
        {
            x: -37,
            y: -417
        },
        {
            x: -126,
            y: -362
        },
        {
            x: -195,
            y: -293
        },
        {
            x: -181,
            y: -203
        },
        {
            x: -100,
            y: -141
        },
        {
            x: 1,
            y: -110
        },
        {
            x: 93,
            y: -92
        },
        {
            x: 174,
            y: -61
        },
        {
            x: 188,
            y: 17
        },
        {
            x: 145,
            y: 81
        },
        {
            x: 82,
            y: 147
        },
        {
            x: 8,
            y: 207
        },
        {
            x: -65,
            y: 262
        },
        {
            x: -144,
            y: 313
        }
    ]
};

if (cleverapps.config.ui === "riddles") {
    cleverapps.styles.BonusWorldLevelView[BonusWorld.TYPE_SUMMER] = {
        width: 85,
        height: 85,
        skinNumbers: true,
        indicator: true,
        jumpHeight: 50,
        delay: 0.4,

        finalPrizePos: {
            x: 0,
            y: 0
        },

        text: {
            x: { align: "center" },
            y: { align: "center" }
        },

        levels: [
            {
                x: 38,
                y: -523,
                invertIndicator: true
            },
            {
                x: -56,
                y: -488,
                invertIndicator: true
            },
            {
                x: -138,
                y: -437,
                invertIndicator: true
            },
            {
                x: -211,
                y: -363,
                invertIndicator: true
            },
            {
                x: -262,
                y: -275,
                invertIndicator: true
            },
            {
                x: -198,
                y: -193
            },
            {
                x: -87,
                y: -170
            },
            {
                x: 35,
                y: -150
            },
            {
                x: 268,
                y: -70
            },
            {
                x: 220,
                y: 14,
                invertIndicator: true
            },
            {
                x: 141,
                y: 84,
                invertIndicator: true
            },
            {
                x: 73,
                y: 164,
                invertIndicator: true
            },
            {
                x: -36,
                y: 201,
                invertIndicator: true
            },
            {
                x: -115,
                y: 259,
                invertIndicator: true
            },
            {
                x: -174,
                y: 337,
                invertIndicator: true
            }
        ]
    };
}

if (cleverapps.config.ui === "tropical") {
    cleverapps.styles.BonusWorldLevelView[BonusWorld.TYPE_SUMMER] = {
        skins: 2,
        width: 80,
        height: 70,

        text: {
            x: { align: "center", dx: 2 },
            y: { align: "center", dy: 2 }
        },

        levels: [
            {
                x: -50,
                y: -455
            },
            {
                x: 41,
                y: -389
            },
            {
                x: 145,
                y: -345
            },
            {
                x: 58,
                y: -245
            },
            {
                x: -75,
                y: -230
            },
            {
                x: -176,
                y: -177
            },
            {
                x: -154,
                y: -82
            },
            {
                x: -32,
                y: -63
            },
            {
                x: 190,
                y: 29
            },
            {
                x: 59,
                y: 80
            },

            {
                x: -68,
                y: 92
            },
            {
                x: -164,
                y: 163
            },
            {
                x: -46,
                y: 202
            },
            {
                x: 70,
                y: 247
            },
            {
                x: 186,
                y: 283
            }
        ]
    };
}
